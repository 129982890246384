@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500&display=swap');
/* 
font-family: 'Roboto', sans-serif; 
font-family: 'Roboto Slab', serif;
*/

.main-header {
    z-index: 999999 !important;position: relative;
}
.top-nav {
    background: #1DB26C;
    font-family: 'Roboto Slab', serif;
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 200;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;

}
.icon-link a{
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    margin-left: 10px;
}
.main-nav {
    background: #fff;
    box-shadow: 0px -6px 15px 0px #00000040;    
    font-family: 'Roboto Slab', serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
}
#booking-table{
    display: none;
}
.title {
    color: #fff;    
    font-family: 'Roboto Slab', serif;
}
.title-batas {    
    margin-bottom: -1px;
}
.text-slider {
    position: absolute;
    top:21px;
    z-index: 9999;    
}
.text-slider h2 {    
    font-family: 'Roboto Slab', serif;
    color: #1DB26C;
    font-size: 23px
}
.text-slider h4 {    
    font-family: 'Roboto', sans-serif; 
    color: #fff;
    font-size:12px;    
    margin-bottom: 15px
}
.img-slide {
    position: relative;
}
.overlay-img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0.5;
    transition: .5s ease;
    background-color: #000;
}
.btn-slide {
    color: #fff;
    background-color: #1DB26C;
    padding: 8px 20px;
    text-decoration: none;    
    font-family: 'Roboto', sans-serif; 
    text-transform: uppercase;
    font-size: 10px
}
.btn-slide:hover {
    color: #fff;
    text-decoration: none;    
    background-color: rgb(29, 149, 93);
}
.batas-slider {
    position: relative;
    top: -26px;
    z-index: 999;
}
.content p {    
    font-family: 'Roboto Slab', serif;
}
.content a {
    font-size: 30px;
    color: #1DB26C;
    margin-right: 10px
}
.menu-title {
    font-family: 'Roboto Slab', serif;
}
.menu-title h4 {
    color: #1DB26C;
}
.menu-list {
    background: #fff;
    /* box-shadow: 0px 1px 5px -2px #00000040;   */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    position: relative;
}
.menu-list p {      
    font-family: 'Roboto', sans-serif; 
}
.menu-list h5 {
    font-size: 16px; 
    font-weight: 600;       
    border-bottom: 2px dotted black;
    padding-bottom: 7px;
}
.menu-list h4 {
    position: absolute;
    font-size: 16px;
    right: 0px;
    top: 0px;
    color: #1DB26C;    
    font-family: 'Roboto Slab', serif;
}
.course-menu h5 {
    font-size: 18px;
    color: #1DB26C; 
    font-family: 'Roboto Slab', serif;
}
.course-menu ul{
    list-style: none;
    padding: 0;
}
.course-menu p{
    margin-bottom: -7px;
}
.course-menu span {
    font-size: 12px;
    margin-bottom: 5px;
}
.image-cooking-sm {
    display: block;
}
.image-cooking-lg {
    display: none;
}
.footer {
    background-color: #1DB26C;
}
.footer-batas {
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
    margin-top: -9px;
}

.insta-profile {
    background-color: #F7F7F7;
}
.res-off {
    display: none
}
.res-on {
    display: block
}
.navbarshadow {
    -webkit-box-shadow: 0 8px 6px -6px #999;
    -moz-box-shadow: 0 8px 6px -6px #999;
    box-shadow: 0 8px 6px -6px #999;

    /* the rest of your styling */
}

/* `sm` applies to x-small devices (portrait phones, less than 576px) */
@media (min-width: 575.98px) { 
    .batas-slider {
        top: -25px;
    }
}

/* `md` applies to small devices (landscape phones, less than 768px) */
@media (min-width: 767.98px) { 
    #booking-table {
        display: block;
    }
    .text-slider {
        top:150px;
    }
    .batas-slider {
        top: -45px;
    }
    .footer-batas {
        margin-top: -1px;
    }
    .image-cooking-sm {
        display: none;
    }
    .image-cooking-lg {
        display: block;
    }
    .res-off {
        display: block
    }
    .res-on {
        display: none
    }
    
}

/* `lg` applies to medium devices (tablets, less than 992px) */
@media (min-width: 991.98px) { 
    .text-slider {
        top:150px;
    }
    .text-slider h2{        
        font-size: 36px
    }
    .text-slider h4{
        font-size: 18px; 
        margin-bottom: 30px;
    }
    .btn-slide {       
    font-size: 16px;    
    padding: 15px 20px;
    }
    .batas-slider {
        top: -65px;
    }
}

/* `xl` applies to large devices (desktops, less than 1200px) */
@media (min-width: 1199.98px) { 
    .batas-slider {
        top: -77px;
    }
}

/* `xxl` applies to x-large devices (large desktops, less than 1400px) */
@media (min-width: 1399.98px) { 

}
